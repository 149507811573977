.staked {
  width: 46px;
  height: 22px;
  background: #23262F;
  border-radius: 32px;
  border: 1px solid #353945;
}

.staked-round {
  width: 17px;
  height: 17px;
  background: #777E90;
  border-radius: 24px;
  position: absolute;
  left: 3px;
  top: 50%;
  transform: translateY(-50%);
}

.staked-check {
  background: #58BD7D;
}

.staked-check .staked-round {
  background: #23262F;
  box-shadow: 0px 24px 24px 1px rgba(15, 15, 15, 0.2);
  left: 24px;
}

.search-input {
  outline: none;
  width: 60%;
  padding-left: 2px;
  background: none;
  color: #fff;
}

.clearInputStyle {
  outline: none;
  background: none;
  padding-left: 8px;
}

.noselect {
  -ms-user-select: none;
  user-select: none;
}

.create-wallet-box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 448px;
  height: 433px;
  background: #23262F;
  box-shadow: 0px 64px 64px 1px rgba(31, 47, 70, 0.12);
  border-radius: 20px;
}

.wallet-adapter-button:not([disabled]):hover {
  background-color: rgb(55 114 255);
}

.wallet-adapter-dropdown {
  width: 100%;
}

input[type=number] {
  -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.bg-black::-webkit-scrollbar,
.list .list-bottom::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}

.list .list-bottom {
  scrollbar-width: none;
  /* firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
  overflow: hidden;
}

/* 设置滚动条的样式 */
/* ::-webkit-scrollbar {
  width: 12px;
} */

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: rgb(239, 239, 239);
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background: #3772FF;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #333;
}



footer svg:hover {
  fill: #3772ff;
}

footer a:hover {
  color: #3772ff;
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
