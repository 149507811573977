::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0) !important;
}

@font-face {
    font-family: 'Abhaya Libre ExtraBold';
    src: url('./font/IBMPlexSans-Light.ttf');
}

@font-face {
    font-family: 'M PLUS 1';
    src: url('./font/IBMPlexSans-Bold.ttf');
}


@font-face {
    font-family: 'Nirmala UI-Bold';
    src: url('./font/StateWide-Regular.ttf');
}
@font-face {
    font-family: 'Nirmala UI-Regular';
    src: url('./font/Montserrat-VariableFont_wght.ttf');
}


