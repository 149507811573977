
  


  
  /*
  1. Add the correct height in Firefox.
  2. Correct the inheritance of border color in Firefox. (https://bugzilla.mozilla.org/show_bug.cgi?id=190655)
  3. Ensure horizontal rules are visible by default.
  */
  /* .ant-btn-default:not(:disabled):hover {
    color: #000;
    border-color: rgba(0,0,0,0);
  } */

  hr {
    height: 0; /* 1 */
    color: inherit; /* 2 */
    border-top-width: 1px; /* 3 */
  }
  
  /*
  Add the correct text decoration in Chrome, Edge, and Safari.
  */
  
  abbr:where([title]) {
    -webkit-text-decoration: underline dotted;
            text-decoration: underline dotted;
  }
  
  /*
  Remove the default font size and weight for headings.
  */
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
    font-weight: inherit;
  }
  
  /*
  Reset links to optimize for opt-in styling instead of opt-out.
  */
  
  a {
    color: inherit;
    text-decoration: inherit;
  }
  
  /*
  Add the correct font weight in Edge and Safari.
  */
  
  b,
  strong {
    font-weight: bolder;
  }
  
  /*
  1. Use the user's configured `mono` font family by default.
  2. Correct the odd `em` font sizing in all browsers.
  */
  
  code,
  kbd,
  samp,
  pre {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; /* 1 */
    font-size: 1em; /* 2 */
  }
  
  /*
  Add the correct font size in all browsers.
  */
  
  small {
    font-size: 80%;
  }
  
  /*
  Prevent `sub` and `sup` elements from affecting the line height in all browsers.
  */
  
  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  
  sub {
    bottom: -0.25em;
  }
  
  sup {
    top: -0.5em;
  }
  
  /*
  1. Remove text indentation from table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
  2. Correct table border color inheritance in all Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
  3. Remove gaps between table borders by default.
  */
  
  table {
    text-indent: 0; /* 1 */
    border-color: inherit; /* 2 */
    border-collapse: collapse; /* 3 */
  }
  
  /*
  1. Change the font styles in all browsers.
  2. Remove the margin in Firefox and Safari.
  3. Remove default padding in all browsers.
  */
  
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit; /* 1 */
    font-size: 100%; /* 1 */
    font-weight: inherit; /* 1 */
    line-height: inherit; /* 1 */
    color: inherit; /* 1 */
    margin: 0; /* 2 */
    padding: 0; /* 3 */
  }
  
  /*
  Remove the inheritance of text transform in Edge and Firefox.
  */
  
  button,
  select {
    text-transform: none;
  }
  
  /*
  1. Correct the inability to style clickable types in iOS and Safari.
  2. Remove default button styles.
  */
  
  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button; /* 1 */
    background-color: transparent; /* 2 */
    background-image: none; /* 2 */
  }
  
  /*
  Use the modern Firefox focus style for all focusable elements.
  */
  
  :-moz-focusring {
    outline: auto;
  }
  
  /*
  Remove the additional `:invalid` styles in Firefox. (https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737)
  */
  
  :-moz-ui-invalid {
    box-shadow: none;
  }
  
  /*
  Add the correct vertical alignment in Chrome and Firefox.
  */
  
  progress {
    vertical-align: baseline;
  }
  
  /*
  Correct the cursor style of increment and decrement buttons in Safari.
  */
  
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    height: auto;
  }
  
  /*
  1. Correct the odd appearance in Chrome and Safari.
  2. Correct the outline style in Safari.
  */
  
  [type='search'] {
    -webkit-appearance: textfield; /* 1 */
    outline-offset: -2px; /* 2 */
  }
  
  /*
  Remove the inner padding in Chrome and Safari on macOS.
  */
  
  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  
  /*
  1. Correct the inability to style clickable types in iOS and Safari.
  2. Change font properties to `inherit` in Safari.
  */
  
  ::-webkit-file-upload-button {
    -webkit-appearance: button; /* 1 */
    font: inherit; /* 2 */
  }
  
  /*
  Add the correct display in Chrome and Safari.
  */
  
  summary {
    display: list-item;
  }
  
  /*
  Removes the default spacing and border for appropriate elements.
  */
  
  blockquote,
  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  figure,
  p,
  pre {
    margin: 0;
  }
  
  fieldset {
    margin: 0;
    padding: 0;
  }
  
  legend {
    padding: 0;
  }
  
  ol,
  ul,
  menu {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  /*
  Prevent resizing textareas horizontally by default.
  */
  
  textarea {
    resize: vertical;
  }
  
  /*
  1. Reset the default placeholder opacity in Firefox. (https://github.com/tailwindlabs/tailwindcss/issues/3300)
  2. Set the default placeholder color to the user's configured gray 400 color.
  */
  
  input::-moz-placeholder, textarea::-moz-placeholder {
    opacity: 1; /* 1 */
    color: #9ca3af; /* 2 */
  }
  
  input:-ms-input-placeholder, textarea:-ms-input-placeholder {
    opacity: 1; /* 1 */
    color: #9ca3af; /* 2 */
  }
  
  input::placeholder,
  textarea::placeholder {
    opacity: 1; /* 1 */
    color: #9ca3af; /* 2 */
  }
  
  /*
  Set the default cursor for buttons.
  */
  
  button,
  [role="button"] {
    cursor: pointer;
  }
  
  /*
  Make sure disabled buttons don't get the pointer cursor.
  */
  :disabled {
    cursor: default;
  }
  
  /*
  1. Make replaced elements `display: block` by default. (https://github.com/mozdevs/cssremedy/issues/14)
  2. Add `vertical-align: middle` to align replaced elements more sensibly by default. (https://github.com/jensimmons/cssremedy/issues/14#issuecomment-634934210)
     This can trigger a poorly considered lint error in some tools but is included by design.
  */

  
  /*
  Constrain images and videos to the parent width and preserve their intrinsic aspect ratio. (https://github.com/mozdevs/cssremedy/issues/14)
  */
  
  img,
  video {
    max-width: 100%;
    height: auto;
  }

  .container {
    width: 100%;
  }
  @media (min-width: 640px) {
  
    .container {
      max-width: 640px;
    }
  }
  @media (min-width: 768px) {
  
    .container {
      max-width: 768px;
    }
  }
  @media (min-width: 1024px) {
  
    .container {
      max-width: 1024px;
    }
  }
  @media (min-width: 1280px) {
  
    .container {
      max-width: 1280px;
    }
  }
  @media (min-width: 1536px) {
  
    .container {
      max-width: 1536px;
    }
  }
  .invisible {
    visibility: hidden;
  }
  .fixed {
    position: fixed;
  }
  .absolute {
    position: absolute;
  }
  .relative {
    position: relative;
  }
  .inset-0 {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
  .top-1\/2 {
    top: 50%;
  }
  .left-1\/2 {
    left: 50%;
  }
  .right-0 {
    right: 0px;
  }
  .top-0 {
    top: 0px;
  }
  .-right-2 {
    right: -0.5rem;
  }
  .-bottom-2 {
    bottom: -0.5rem;
  }
  .-right-5 {
    right: -1.25rem;
  }
  .-top-5 {
    top: -1.25rem;
  }
  .left-0 {
    left: 0px;
  }
  .top-8 {
    top: 2rem;
  }
  .right-2 {
    right: 0.5rem;
  }
  .z-30 {
    z-index: 30;
  }
  .z-20 {
    z-index: 20;
  }
  .z-10 {
    z-index: 10;
  }
  .z-50 {
    z-index: 50;
  }
  .my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .mb-8 {
    margin-bottom: 2rem;
  }
  .mr-4 {
    margin-right: 1rem;
  }
  .mt-4 {
    margin-top: 1rem;
  }
  .mr-12 {
    margin-right: 3rem;
  }
  .mr-8 {
    margin-right: 2rem;
  }
  .mr-20 {
    margin-right: 5rem;
  }
  .ml-11 {
    margin-left: 2.75rem;
  }
  .ml-12 {
    margin-left: 3rem;
  }
  .mt-8 {
    margin-top: 2rem;
  }
  .mt-12 {
    margin-top: 3rem;
  }
  .mt-2 {
    margin-top: 0.5rem;
  }
  .ml-24 {
    margin-left: 6rem;
  }
  .mr-24 {
    margin-right: 6rem;
  }
  .ml-6 {
    margin-left: 1.5rem;
  }
  .mt-3 {
    margin-top: 0.75rem;
  }
  .mr-2 {
    margin-right: 0.5rem;
  }
  .mb-2 {
    margin-bottom: 0.5rem;
  }
  .mb-4 {
    margin-bottom: 1rem;
  }
  .mr-3 {
    margin-right: 0.75rem;
  }
  .mr-16 {
    margin-right: 4rem;
  }
  .mt-10 {
    margin-top: 2.5rem;
  }
  .mb-10 {
    margin-bottom: 2.5rem;
  }
  .block {
    display: block;
  }
  .flex {
    display: flex;
  }
  .grid {
    display: grid;
  }
  .hidden {
    display: none;
  }
  .h-10 {
    height: 2.5rem;
  }
  .h-full {
    height: 100%;
  }
  .h-16 {
    height: 4rem;
  }
  .h-20 {
    height: 5rem;
  }
  .h-48 {
    height: 48px;
  }
  .h-647 {
    height: 647px;
  }
  .h-6 {
    height: 1.5rem;
  }
  .h-12 {
    height: 3rem;
  }
  .h-auto {
    height: auto;
  }
  .h-0 {
    height: 0px;
  }
  .h-36 {
    height: 9rem;
  }
  .h-32 {
    height: 8rem;
  }
  .h-44 {
    height: 11rem;
  }
  .h-24 {
    height: 6rem;
  }
  .h-7 {
    height: 1.75rem;
  }
  .h-8 {
    height: 2rem;
  }
  .w-10 {
    width: 2.5rem;
  }
  .w-32 {
    width: 8rem;
  }
  .w-full {
    width: 100%;
  }
  .w-px {
    width: 1px;
  }
  .w-29 {
    width: 7.625rem;
  }
  .w-1 {
    width: 0.25rem;
  }
  .w-174 {
    width: 174px;
  }
  .w-1\/2 {
    width: 50%;
  }
  .w-64 {
    width: 16rem;
  }
  .w-6 {
    width: 1.5rem;
  }
  .w-28 {
    width: 7rem;
  }
  .w-12 {
    width: 3rem;
  }
  .w-20 {
    width: 5rem;
  }
  .w-80 {
    width: 20rem;
  }
  .w-24 {
    width: 6rem;
  }
  .w-26 {
    width: 8rem;
  }
  .w-40 {
    width: 10rem;
  }
  .w-1\/5 {
    width: 20%;
  }
  .w-14 {
    width: 3.5rem;
  }
  .w-2\/5 {
    width: 40%;
  }
  .max-w-screen-xl {
    max-width: 1440px;
  }
  .max-w-screen-544 {
    max-width: 544px;
  }
  .max-w-screen-444 {
    max-width: 444px;
  }
  .flex-shrink {
    flex-shrink: 1;
  }
  .-translate-x-1\/2 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .-translate-y-1\/2 {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .transform-gpu {
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .cursor-pointer {
    cursor: pointer;
  }  
  .cursor-no-drop {
    cursor: no-drop;
  }
  .resize {
    resize: both;
  }
  .grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .flex-col {
    flex-direction: column;
  }
  .items-start {
    align-items: flex-start;
  }
  .items-center {
    align-items: center;
  }
  .justify-start {
    justify-content: flex-start;
  }
  .justify-end {
    justify-content: flex-end;
  }
  .justify-center {
    justify-content: center;
  }
  .justify-between {
    justify-content: space-between;
  }
  .justify-around {
    justify-content: space-around;
  }
  .gap-4 {
    gap: 1rem;
  }
  .gap-1 {
    gap: 0.25rem;
  }
  .gap-y-8 {
    row-gap: 2rem;
  }
  .gap-x-20 {
    -moz-column-gap: 5rem;
         column-gap: 5rem;
  }
  .overflow-auto {
    overflow: auto;
  }
  .overflow-hidden {
    overflow: hidden;
  }
  .rounded-12 {
    border-radius: 12px;
  }
  .rounded-c {
    border-radius: 50%;
  }
  .rounded-90 {
    border-radius: 90px;
  }
  .rounded-20 {
    border-radius: 20px;
  }
  .rounded-8 {
    border-radius: 8px;
  }
  .rounded-tl-90 {
    border-top-left-radius: 90px;
  }
  .rounded-bl-90 {
    border-bottom-left-radius: 90px;
  }
  .rounded-tr-90 {
    border-top-right-radius: 90px;
  }
  .rounded-br-90 {
    border-bottom-right-radius: 90px;
  }
  .border {
    border-width: 1px;
  }
  .border-t {
    border-top-width: 1px;
  }
  .border-r {
    border-right-width: 1px;
  }
  .border-b {
    border-bottom-width: 1px;
  }
  .border-solid {
    border-style: solid;
  }
  .border-list {
    --tw-border-opacity: 1;
    border-color: rgb(63 69 83 / var(--tw-border-opacity));
  }
  .border-line {
    --tw-border-opacity: 1;
    border-color: rgb(35 38 47 / var(--tw-border-opacity));
  }
  .border-btn {
    --tw-border-opacity: 1;
    border-color: #494747;
  }
  .border-btn2 {
    --tw-border-opacity: 1;
    border-color: rgb(119 126 144 / var(--tw-border-opacity));
  }
  .bg-black {
    --tw-bg-opacity: 1;
    background-color: rgb(20 20 22 / var(--tw-bg-opacity));
  }
  .bg-black5 {
    --tw-bg-opacity: 1;
    background-color: rgb(63 69 83 / var(--tw-bg-opacity));
  }
  .bg-btn {
    --tw-bg-opacity: 1;
    background-color: #fff;
  }
  .bg-black2 {
    --tw-bg-opacity: 1;
    background-color: #333;
  }
  .bg-black3 {
    --tw-bg-opacity: 1;
    background-color: rgb(53 57 69 / var(--tw-bg-opacity));
  }
  .bg-black1 {
    --tw-bg-opacity: 1;
    background-color: rgb(24 25 29 / var(--tw-bg-opacity));
  }
  .bg-black4 {
    --tw-bg-opacity: 1;
    background-color: #323232;
  }
  .bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }
  .p-6 {
    padding: 1.5rem;
  }
  .p-24 {
    padding: 6rem;
  }
  .p-4 {
    padding: 1rem;
  }
  .py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .hover-link:hover {
    /* border-bottom: 1px solid #fff;
    border-radius: 0; */
  }
  .py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .pt-12 {
    padding-top: 3rem;
  }
  .pr-8 {
    padding-right: 2rem;
  }
  .pb-10 {
    padding-bottom: 2.5rem;
  }
  .pt-20 {
    padding-top: 5rem;
  }
  .pb-24 {
    padding-bottom: 6rem;
  }
  .pt-2 {
    padding-top: 0.5rem;
  }
  .pt-4 {
    padding-top: 1rem;
  }
  .pr-20 {
    padding-right: 5rem;
  }
  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }
  .text-29xl {
    font-size: 29px;
  }
  .text-4xl {
    font-size: 14px;
  }
  .text-14xl {
    font-size: 24px;
  }
  .text-2xl {
    font-size: 12px;
  }
  .text-8xl {
    font-size: 18px;
  }
  .text-64xl {
    font-size: 64px;
  }
  .text-40xl {
    font-size: 40px;
  }
  .text-30xl {
    font-size: 30px;
  }
  .text-10xl {
    font-size: 20px;
  }
  .text-9xl {
    font-size: 19px;
  }
  .text-6xl {
    font-size: 16px;
  }
  .text-48xl {
    font-size: 48px;
  }
  .text-7xl {
    font-size: 17px;
  }
  .text-3xl {
    font-size: 13px;
  }
  .font-medium {
    font-weight: 500;
  }
  .font-light {
    font-weight: 300;
  }
  .leading-8 {
    line-height: 2rem;
  }
  .leading-64 {
    line-height: 64px;
  }
  .leading-24 {
    line-height: 24px;
  }
  .leading-9 {
    line-height: 2.25rem;
  }
  .tracking-widest {
    letter-spacing: 0.1em;
  }
  /* .text-text1 {
    --tw-text-opacity: 1;
    color: rgb(119 126 144 / var(--tw-text-opacity));
  } */
  /* .text-text2 {
    --tw-text-opacity: 1;
    color: #494747;
  } */
  .text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .text-white1 {
    --tw-text-opacity: 1;
    color: rgb(244 245 246 / var(--tw-text-opacity));
  }
  .text-text1 {
    --tw-text-opacity: 1;
    color: #999;
  }
  .text-text2 {
    --tw-text-opacity: 1;
    color: #fff;
  }
  .text-text3 {
    --tw-text-opacity: 1;
    color: #494747;
  }
  .text-text4 {
    --tw-text-opacity: 1;
    color: #fff;
  }
  .text-red {
    --tw-text-opacity: 1;
    color: rgb(241 77 77 / var(--tw-text-opacity));
  }
  .underline {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
  }
  .filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }
  .transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .duration-500 {
    transition-duration: 500ms;
  }
  .duration-200 {
    transition-duration: 200ms;
  }
  .ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
  .hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
  .hover\:text-text2:hover {
    --tw-text-opacity: 1;
    color: rgb(252 252 253 / var(--tw-text-opacity));
  }
  @media (min-width: 768px) {
  
    .md\:w-96 {
      width: 24rem;
    }
  }
  @media (min-width: 1024px) {
  
    .lg\:container {
      width: 100%;
    }
  
    @media (min-width: 640px) {
  
      .lg\:container {
        max-width: 640px;
      }
    }
  
    @media (min-width: 768px) {
  
      .lg\:container {
        max-width: 768px;
      }
    }
  
    @media (min-width: 1024px) {
  
      .lg\:container {
        max-width: 1024px;
      }
    }
  
    @media (min-width: 1280px) {
  
      .lg\:container {
        max-width: 1280px;
      }
    }
  
    @media (min-width: 1536px) {
  
      .lg\:container {
        max-width: 1536px;
      }
    }
  
    .lg\:mx-auto {
      margin-left: auto;
      margin-right: auto;
    }
  
    .lg\:block {
      display: block;
    }
  
    .lg\:hidden {
      display: none;
    }
  
    .lg\:h-72 {
      height: 18rem;
    }
  
    .lg\:w-44 {
      width: 11rem;
    }
  
    .lg\:w-96 {
      width: 24rem;
    }
  
    .lg\:w-full {
      width: 100%;
    }
  
    .lg\:w-72 {
      width: 18rem;
    }
  
    .lg\:max-w-screen-xl {
      max-width: 1440px;
    }
  
    .lg\:cursor-pointer {
      cursor: pointer;
    }
  
    .lg\:grid-cols-2 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  
    .lg\:rounded-20 {
      border-radius: 20px;
    }
  
    .lg\:border-b {
      border-bottom-width: 1px;
    }
  
    .lg\:px-12 {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  
    .lg\:px-32 {
      padding-left: 8rem;
      padding-right: 8rem;
    }
  
    .lg\:py-20 {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }
  
    .lg\:px-5 {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }
  
    .lg\:py-6 {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  
    .lg\:px-8 {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  
    .lg\:text-36xl {
      font-size: 36px;
    }
  
    .lg\:text-30xl {
      font-size: 30px;
    }
  
    .lg\:text-4xl {
      font-size: 14px;
    }
  }
  @media (min-width: 1536px) {
  
    .\32xl\:w-96 {
      width: 24rem;
    }
  
    .\32xl\:grid-cols-3 {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  .staked {
    width: 46px;
    height: 22px;
    background: #23262F;
    border-radius: 32px;
    border: 1px solid #353945;
  }
  
  .staked-round {
    width: 17px;
    height: 17px;
    background: #777E90;
    border-radius: 24px;
    position: absolute;
    left: 3px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .staked-check {
    background: #888;
  }
  
  .staked-check .staked-round {
    background: #23262F;
    box-shadow: 0px 24px 24px 1px rgba(15, 15, 15, 0.2);
    left: 24px;
  }
  
  .search-input {
    outline: none;
    border: none;
    width: 60%;
    padding-left: 2px;
    background: none;
    color: #fff;
  }
  
  .clearInputStyle {
    outline: none;
    background: none;
    padding-left: 8px;
  }
  
  .noselect {
    -ms-user-select: none;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
  }
  
  .create-wallet-box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 448px;
    height: 433px;
    background: #23262F;
    box-shadow: 0px 64px 64px 1px rgba(31, 47, 70, 0.12);
    border-radius: 20px;
  }
  
  .wallet-adapter-button:not([disabled]):hover {
    background-color: rgb(55 114 255);
  }
  
  .wallet-adapter-dropdown {
    width: 100%;
  }
  
  input[type=number] {
    -moz-appearance: textfield;
  }
  
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .bg-black::-webkit-scrollbar,
  .list .list-bottom::-webkit-scrollbar {
    display: none;
    /* Chrome Safari */
  }
  
  .list .list-bottom {
    scrollbar-width: none;
    /* firefox */
    -ms-overflow-style: none;
    /* IE 10+ */
    overflow: hidden;
  }
  
  /* 设置滚动条的样式 */
  /* ::-webkit-scrollbar {
    width: 12px;
  } */
  
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: rgb(239, 239, 239);
    border-radius: 2px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #3772FF;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #333;
  }
  
  
  
  footer svg:hover {
    fill: #3772ff;
  }
  
  footer a:hover {
    color: #3772ff;
  }
  
  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }