@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
blockquote,
dl,
dt,
dd,
ul,
ol,
li,
pre,
form,
fieldset,
legend,
button,
input,
textarea,
th,
td {
  margin: 0;
  padding: 0;
}

body,
button,
input,
select,
textarea {
  font: 12px/1.5tahoma, arial, \5b8b\4f53;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
}

address,
cite,
dfn,
em,
var {
  font-style: normal;
}

code,
kbd,
pre,
samp {
  font-family: couriernew, courier, monospace;
}

small {
  font-size: 12px;
}

ul,
ol {
  list-style-position: inside;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

legend {
  color: #000;
}

fieldset,
img {
  border: 0;
}

button,
input,
select,
textarea {
  font-size: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

iframe {
  display: none !important;
}

p {
  margin-bottom: 0;
}

.corsor-pointer {
  cursor: pointer;
}

.corsor-not-allowed {
  cursor: not-allowed;
}

html {
  line-height: 1.3;
  font-family: Nirmala UI-Regular;
}

.h5-menu .ant-drawer-wrapper-body {
  background: rgba(0, 0, 0, 1);
}

.h5-menu .ant-drawer-body {
  padding: 0;
}

/* body {
  overflow: scroll;
} */

body::-webkit-scrollbar-thumb {
  background-color: #018EE8;
  height: 50px;
  outline-offset: -2px;
  outline: 2px solid #20AFFF;
  -webkit-border-radius: 4px;
  border: 2px solid #20AFFF;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #FB4446;
  height: 50px;
  -webkit-border-radius: 4px;
}

body::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

body::-webkit-scrollbar-track-piece {
  background-color: #2A303E;
  -webkit-border-radius: 0;
}

/* body::-webkit-scrollbar { width: 0 !important } */




.mint-message {
  width: 640px !important;
  top: 200px;
}

.h5-mint-message {
  top: 200px;
}

.mint-message .ant-modal-content {
  background: #232323;
  border-radius: 37px;
  width: 640px;
  height: 442px;
}

.h5-mint-message .ant-modal-content {
  background: #232323;
  border-radius: 37px;
}

.mint-message .ant-modal-close,
.h5-mint-message .ant-modal-close {
  width: 44px;
  height: 44px;
}

.mint-message .ant-modal-body,
.h5-mint-message .ant-modal-body {
  height: 100%;
}

.mint-message .ant-modal-header,
.h5-mint-message .ant-modal-header {
  opacity: 0;
}

.arrow180 {
  transform: rotate(180deg);
}

.ant-popover-inner {
  background: #1A2130 !important;
}

.ant-popover-arrow {
  display: none !important;
}

.ant-message {
  z-index: 2501 !important;
}

.styledHeaderTextC {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #FFFFFF; */
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  font-size: 16px;
  font-family: Nirmala UI-Regular, Nirmala UI;
  font-weight: 400;
  color: #000000;
  line-height: 32px;
}




.h-menu {
  background: rgba(0, 0, 0, 0) !important;
}

.h-menu .ant-drawer-body {
  background: #333;
}

.h-menu .ant-drawer-header {
  padding: 0 !important;
  display: none;
}

.h-menu .ant-drawer-header-title {
  height: 69px !important;
}

.h-menu .submenu {
  height: 31px;
  overflow: hidden;
  margin-bottom: 10px;
}

.h-menu .submenu .c {
  margin-left: 32px;
  padding: 4px 0;
}

.h-menu .show-c {
  height: auto;
}


.ant-select-selector {
  color: #8A8A8A !important;
  border-radius: 12px 12px 12px 12px !important;
  border: 1px solid #323232 !important;
  background: #1A1A1A !important;
}

/* .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #000 !important;

}
/* 没选中 */
/* .ant-checkbox-inner {
  background-color: #888 !important;
  border-color: #fff !important;
} */

.ant-checkbox-wrapper {
  font-size: 14px !important;
  color: #fff !important;
  font-family: "Nirmala UI-Regular" !important;
}

.ant-checkbox-checked:after {
  border: none !important;
}