

.buy-tickets-modal .ant-modal-content {
  padding: 0;
  border-radius: 21px;
  background-color: rgba(0,0,0,0);
}

.cursor {
  cursor: pointer;
}
.no-drop {
  cursor: no-drop;
}

.enter-done {
  z-index: 2500 !important;
}

/* .ant-select-dropdown-menu,
.ant-select-dropdown-menu-root,
.ant-select-dropdown-menu-vertical li {
    color: white !important;
}
.ant-select-dropdown {
    color: white !important;
    background-color: #1A1A1A !important;
} */

video::-webkit-media-controls { display:none !important; } 

/* //全屏按钮 */
video::-webkit-media-controls-fullscreen-button {
    display: none;
}
/* //播放按钮 */
video::-webkit-media-controls-play-button {
    display: none;
}
/* //进度条 */
video::-webkit-media-controls-timeline {
    display: none;
}
/* //观看的当前时间 */
video::-webkit-media-controls-current-time-display{
    display: none;            
}
/* //剩余时间 */
video::-webkit-media-controls-time-remaining-display {
    display: none;            
}
/* //音量按钮 */
video::-webkit-media-controls-mute-button {
    display: none;            
}
video::-webkit-media-controls-toggle-closed-captions-button {
    display: none;            
}
/* //音量的控制条 */
video::-webkit-media-controls-volume-slider {
    display: none;            
}
/* //所有控件-通过取消video标签的controls属性更方法 */
video::-webkit-media-controls-enclosure{ 
    display: none;
}

video::-webkit-media-controls-panel {
  display: none!important;
  
  -webkit-appearance: none;
  
}

video::-webkit-media-controls-play-button {
  display: none!important;

  -webkit-appearance: none;

}
video::--webkit-media-controls-play-button {
  display: none!important;

  -webkit-appearance: none;

}

video::-webkit-media-controls-start-playback-button {
  display: none!important;

  -webkit-appearance: none;

}

:where(.css-diro6f).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: rgb(26, 26, 26);
  border: 1px solid rgb(50, 50, 50) !important;
}