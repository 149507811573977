
.walletModal .ant-modal-content {
  background: #232323 !important;
}
.walletModal .ant-modal-header {
  background: #232323 !important;
}
.walletModal .ant-modal-header .ant-modal-title {
  color: #fff !important;
  font-size: 27px;
}

.walletModal .ant-modal-content {
  border: 1px solid #1E2635;
  border-radius: 12px;
  background: #232323 !important;
}
.walletModal .ant-modal-close-x {
  color: #fff !important;
  font-size: 27px;
}
.walletModal .ant-modal-body {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.m-walletModal .ant-modal-body {
  height: 200px;
}

.walletModal {
  top: calc(30vh - 92px) !important;
}